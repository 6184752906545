.custom-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'bluberry' !important;
  font-size: 24px;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.custom-icon-select {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'bluberry' !important;
  font-size: 10px;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  cursor: pointer;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-icon {
  font-family: 'bluberry' !important;
  font-size: 20px;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  cursor: pointer;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sumary-sidebar {
  height: 88%;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 96px;
  background-color: white;
  z-index: 100;
  box-shadow: 0 8px 8px 0 rgba(10, 10, 10, 0.29);
}

.scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow: scroll;
}
.scrollbar::-webkit-scrollbar {
  display: none;
}

.logo-image-mini {
  width: 27px;
  height: auto;
  animation: rotate 2s linear infinite;
}

.logo-image-list {
  margin: auto;
  width: 32%;
  height: auto;
}

.logo-image-letters {
  width: 100px;
  margin-top: 10px;
  margin-left: 8px;
}

.logo-image {
  width: 135px;
  height: auto;
  margin-left: 6px !important;
  animation: rotate 2s linear infinite;
}

//Logo container
.MuiToolbar-root {
  padding-left: 16px !important;
}

.icon-calculator:before {
  content: '\e909';
}
.icon-edit:before {
  content: '\e910';
}
.icon-arrow-left:before {
  content: '\e908';
}
.icon-pending:before {
  content: '\e90c';
}
.icon-active:before {
  content: '\e90d';
}
.icon-inactive:before {
  content: '\e90e';
}
.icon-filter:before {
  content: '\e90f';
}
.icon-select:before {
  content: '\e8fe';
}
.icon-search:before {
  content: '\e8ff';
}
.icon-logout:before {
  content: '\e8f0';
}
.icon-email:before {
  content: '\e8f1';
}
.icon-photo-upload:before {
  content: '\e8f2';
}
.icon-password:before {
  content: '\e8f3';
}
.icon-phone:before {
  content: '\e8f4';
}
.icon-profile:before {
  content: '\e8f5';
}
.icon-settings:before {
  content: '\e900';
}
.icon-catalog:before {
  content: '\e901';
}
.icon-delete:before {
  content: '\e902';
}
.icon-clear:before {
  content: '\e903';
}
.icon-add:before {
  content: '\e904';
}
.icon-move:before {
  content: '\e905';
}
.icon-upload:before {
  content: '\e906';
}
.icon-info:before {
  content: '\e907';
}
.icon-dashboard:before {
  content: '\e8e0';
}
.icon-add-menu:before {
  content: '\e8e1';
}
.icon-timesheet:before {
  content: '\e8e2';
}
.icon-calendar:before {
  content: '\e8e3';
}
.icon-chat:before {
  content: '\e8e4';
}
.icon-documents:before {
  content: '\e8e5';
}
.icon-resendEmail:before {
  content: '\e8fd';
}
.icon-clock:before {
  content: '\e90a';
}
.icon-warning:before {
  content: '\e90b';
}
.icon-eye:before {
  content: '\e911';
}
.icon-help:before {
  content: '\e912';
}
.icon-cancelCircle:before {
  content: '\e913';
}
.icon-project:before {
  content: '\e8a1';
}
.icon-lead:before {
  content: '\e8a2';
}
.icon-resources:before {
  content: '\e8a3';
}
.icon-customer:before {
  content: '\e8a4';
}
.icon-pin:before {
  content: '\e8a5';
}
.icon-users:before {
  content: '\e914';
}
.icon-classic-phone:before {
  content: '\e915';
}
.icon-shieldOk:before {
  content: '\e916';
}
.icon-archived:before {
  content: '\e917';
}
.icon-unarchived:before {
  content: '\e918';
}
.icon-lock:before {
  content: '\e919';
}
.icon-unlock:before {
  content: '\e91a';
}
.icon-save:before {
  content: '\e91b';
}
.icon-assignments:before {
  content: '\e8e8';
}
.icon-pending-file:before {
  content: '\e91c';
}
.icon-delete-file:before {
  content: '\e91d';
}
.icon-note:before {
  content: '\e91e';
}
.icon-location:before {
  content: '\e91f';
}
.icon-payment:before {
  content: '\e920';
}
.icon-doubleArrow:before {
  content: '\e921';
}
.icon-simpleArrow:before {
  content: '\e922';
}
.icon-userSelected:before {
  content: '\e923';
}
.icon-company:before {
  content: '\e924';
}
.icon-warningTriangle:before {
  content: '\e925';
}
.icon-backspace:before {
  content: '\e926';
}
.icon-receivedEmail:before {
  content: '\e927';
}
.icon-download:before {
  content: '\e928';
}
.icon-download1:before {
  content: '\e929';
}
.custom-style {
  font-size: x-large;
}
