.ripple-animation {
  border-radius: 50%;
  display: grid;
  animation: ripple-animation 1.5s linear infinite;
}

.ripple-animation::before,
.ripple-animation::after {
  grid-area: 1/1;
  border-radius: 50%;
  animation: inherit;
}

@keyframes ripple-animation {
  0% {
    box-shadow: 0 0 0 0.1rem rgba(104, 165, 80, 0.9);
  }
  100% {
    box-shadow: 0 0 0 0.9rem rgba(104, 165, 80, 0.1);
  }
}
